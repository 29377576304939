import React from 'react';

import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { Link, withPrefix } from 'gatsby';
import { useAuth0 } from '@auth0/auth0-react';
import * as stripe from '../services/stripe-service';
import { A } from '../components/a';
import Switch from '../components/price-switch';
import EmailPopup from '../components/email-popup';
import Seo from '../components/seo'

const auth0Options = {
  audience: process.env.GATSBY_AUTH0_AUDIENCE,
  scope: process.env.GATSBY_AUTH0_SCOPE,
};

const isProOptionAvailable = process.env.GATSBY_PRICING_PRO_OPTION === "pro-option-true";

const Pricing = () => {
  // const [priceSwitchYearly, setPriceSwitchYearly] = React.useState(false);
  const priceSwitchYearly = true // Temporarily, price switch has been removed
  const [buttonsEnabled, setButtonsEnabled] = React.useState(true);
  const [hasSubscription, setHasSubscription] = React.useState(false);
  const [subscriptionInfoFetched, setSubscriptionInfoFetched] = React.useState(false);
  const [emailVerified, setEmailVerified] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState('');
  const [verificationInfoVisible, setVerificationInfoVisible] = React.useState(false);

  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  // const setEnabledHandler = (enabled: boolean) => {
  //   setPriceSwitchYearly(enabled)
  // }

  const loadAccountInfo = React.useCallback(async () => {
    if (!isAuthenticated) {
      if (!isLoading) {
        setSubscriptionInfoFetched(true);
      }
      return;
    }
    const claims = await getIdTokenClaims(auth0Options);
    const token = await getAccessTokenSilently(auth0Options);
    setEmailVerified(!!claims?.email_verified);
    setAccessToken(token);
    const subscribed = await stripe.hasSubscription(token);
    setHasSubscription(subscribed);
    setSubscriptionInfoFetched(true);
  }, [isAuthenticated, isLoading]);

  let subscriptionId: string;

  if (priceSwitchYearly) {
    subscriptionId = process.env.GATSBY_STRIPE_PRO_ANNUAL_PRICE_ID ||  'missing-price-id';
  } else {
    subscriptionId = process.env.GATSBY_STRIPE_PRO_MONTHLY_PRICE_ID ||  'missing-price-id';
  }

  const redirectToSubscription = React.useCallback(async () => {
    if (!buttonsEnabled) {
      return;
    }
    setButtonsEnabled(false);
    // if (
    //   !emailVerified &&
    //   !confirm('Ön még nem erősítette meg az e-mail címét. Biztosan előfizet?')
    // ) {
    //   return;
    // }
    const redirectUrl = await stripe.getSubscriptionUrl(accessToken, subscriptionId);
    window.location.assign(redirectUrl);
  }, [emailVerified, accessToken, priceSwitchYearly]);

  const redirectToMySubscription = React.useCallback(async () => {
    if (!buttonsEnabled) {
      return;
    }
    setButtonsEnabled(false);
    const redirectUrl = await stripe.getMySubscriptionUrl(accessToken);
    window.location.assign(redirectUrl);
  }, [accessToken]);

  React.useEffect(() => {
    console.log('useEffect');
    loadAccountInfo().catch(console.error);
  }, [loadAccountInfo]);

  React.useEffect(() => {
    const listener = (e: PageTransitionEvent) => {
      if (e.persisted) {
        // Got here using the browser "Back" or "Forward" button.
        setButtonsEnabled(true);
      }
    };
    window.addEventListener('pageshow', listener, false);
    return () => window.removeEventListener('pageshow', listener);
  }, [setButtonsEnabled]);

  const columnLayout = isProOptionAvailable ? "col-lg-4" : "col-lg-5";

  return (
    <div id="main_content">
      <Seo title='Pricing'/>
      <Navbar dark={true} />
      <EmailPopup/>

      <section className="page-banner">
        <div className="container">
          <div className="page-title-wrapper">
            <h1 className="page-title">Pricing</h1>

            <ul className="bradcurmed">
              <li>
                <Link to="/">
                  Home
                </Link>
              </li>
              <li>Pricing</li>
            </ul>
          </div>
        </div>

        <svg
          className="circle"
          data-parallax='{"x" : -200}'
          xmlns="http://www.w3.org/2000/svg"
          width="950px"
          height="950px"
        >
          <path
            fillRule="evenodd"
            stroke="rgb(250, 112, 112)"
            strokeWidth="100px"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            opacity="0.051"
            fill="none"
            d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
          />
        </svg>

        <ul className="animate-ball">
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
        </ul>
      </section>
      {isAuthenticated && verificationInfoVisible && (

        <div className="email-verification">
          <p>
            Oops! It seems that you haven't validated your email address yet. <br />
            Are you sure you want to continue with
            <span> {user?.email}</span>?
          </p>
          <ul>
            <li className="pix-btn"
              onClick={() => {
                setVerificationInfoVisible(false)
                redirectToSubscription()
              }}
            > Buy it now
            </li>
            <li className="pix-btn btn-outline btn-cancel"
              onClick={() => {
                setVerificationInfoVisible(false)
              }}
            > Cancel
            </li>
          </ul>
        </div>

      )
      }

      <section className="pricing-single-one">
        <div className="container">
          <div className="section-title text-center">
            <h3 className="sub-title wow pixFadeUp">Pricing Plan</h3>
            <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
              Choose your Plan
            </h2>
            {/* <Switch setEnabled={setEnabledHandler}
              enabled={priceSwitchYearly}
              on='Yearly'
              off='Monthly'
              wowDelay='0.5s'
              switchClassName='switch-container wow pixFadeUp'
            ></Switch> */}
          </div>

          <div
            className="row advanced-pricing-table no-gutters wow pixFadeUp justify-content-center"
            data-wow-delay="0.5s"
          >
            <div className={columnLayout}>
              <div className="pricing-table br-left">
                <div className="pricing-header pricing-amount">
                  <h3 className="price-title">Free</h3>
                  {priceSwitchYearly && (
                    <div className="annual_price">
                      <h2 className="price">$0.00</h2>
                    </div>
                  )}
                  {!priceSwitchYearly && (
                    <div className="monthly_price">
                      <h2 className="price">$0.00</h2>
                    </div>
                  )}
                </div>
                <ul className="price-feture">
                  <li className="not">Character limit*</li>
                  <li className="have">Email support available</li>
                  <li className="have">Community support in <a target="_blank" href='https://www.facebook.com/groups/3323244291274369'>English</a> and <a target="_blank" href='https://www.facebook.com/groups/tananyagfejlesztoleszek'>Hungarian</a></li>
                  <li className="have">Publishing - with character limit</li>
                </ul>
                <div className="action text-center">
                  <Link to="/getting-started" className="pix-btn btn-outline">
                    Download
                  </Link>
                </div>
              </div>
            </div>

            {isProOptionAvailable && (
            
            <div className="col-lg-4">
              <div className="pricing-table color-two">
                <div className="pricing-header pricing-amount">
                  <h3 className="price-title">Pro</h3>

                  {priceSwitchYearly && (
                    <div className="annual_price">
                      <h2 className="price">$800.00
                       <span style={{fontSize:'14px', fontStyle: 'italic'}}>/year</span>
                      </h2>
                    </div>
                  )}
                  {!priceSwitchYearly && (
                    <div className="monthly_price">
                      <h2 className="price">$85.00</h2>
                    </div>
                  )}
                </div>
                <ul className="price-feture">
                  <li className="have">No character limit</li>
                  <li className="have">Email support available</li>
                  <li className="have">Community support in <a target="_blank" href='https://www.facebook.com/groups/3323244291274369'>English</a> and <a target="_blank" href='https://www.facebook.com/groups/tananyagfejlesztoleszek'>Hungarian</a></li>
                  <li className="have">Publishing - unlimited characters</li>
                </ul>

                <div className="action text-center">

                  {/* Loading auth info*/}
                  {isLoading && (
                    <button
                      className="pix-btn btn-outline"
                    > Buy it now
                    </button>
                  )}

                  {/* No login - buy */}
                  {!isLoading && !isAuthenticated && (
                    <button
                      className="pix-btn btn-outline"
                      disabled={!buttonsEnabled}
                      onClick={() => {
                        setButtonsEnabled(false);
                        loginWithRedirect({
                          redirectUri: withPrefix(
                            process.env.GATSBY_AUTH0_CALLBACK_BASE_URI + '/autosubscribe?price_id=' + subscriptionId
                          ),
                        });
                      }
                      }
                    > Buy it now
                    </button>
                  )}

                  {/* Logged in, Loading subscription info */}
                  {!isLoading && isAuthenticated && !subscriptionInfoFetched && (
                    <button
                      className="pix-btn btn-outline"
                      disabled={true}
                    > Buy it now
                    </button>
                  )}

                  {/*Logged in, Email not verified, no subscription yet*/}
                  {!isLoading && isAuthenticated && subscriptionInfoFetched  && !hasSubscription && !emailVerified && (
                    <button
                      className="pix-btn btn-outline"
                      disabled={!buttonsEnabled}
                      onClick={() => {
                        setVerificationInfoVisible(true)
                      }
                      }
                    > Buy it now
                    </button>
                  )}

                  {/*Logged in, Email not verified, no subscription yet*/}
                  {!isLoading && isAuthenticated && subscriptionInfoFetched  && !hasSubscription && emailVerified && (
                    <button
                      className="pix-btn btn-outline"
                      disabled={!buttonsEnabled}
                      onClick={() => {
                        redirectToSubscription()
                      }
                      }
                    > Buy it now
                    </button>
                  )}

                  {/*Logged in, has subscription */}
                  {!isLoading && isAuthenticated && subscriptionInfoFetched && hasSubscription && (
                    <button
                      className="pix-btn btn-outline"
                      disabled={!buttonsEnabled}
                      onClick={() => {
                        redirectToMySubscription()
                      }}
                    >
                      My Plan
                    </button>
                  )}

                </div>
              </div>
            </div>
            )}

            <div className={columnLayout}>
              <div className="pricing-table color-three">
                <div className="pricing-header pricing-amount">
                  <h3 className="price-title">Enterprise</h3>
                  {priceSwitchYearly && (
                    <div className="annual_price">
                      <h2 className="price">Contact us</h2>
                    </div>
                  )}
                  {!priceSwitchYearly && (
                    <div className="monthly_price">
                      <h2 className="price">Contact us</h2>
                    </div>
                  )}
                </div>
                <ul className="price-feture">
                  <li className="have">No character limit</li>
                  <li className="have">Email support within 24 hours</li>
                  <li className="have">Community support in <a target="_blank" href='https://www.facebook.com/groups/3323244291274369'>English</a> and <a target="_blank" href='https://www.facebook.com/groups/tananyagfejlesztoleszek'>Hungarian</a></li>
                  <li className="have">You can send your course material for review</li>
                  <li className="have">Publishing - unlimited characters</li>
                  <li className="have">Personalised coaching</li>
                  <li className="have">Closed workshop</li>
                </ul>
                <div className="action text-center">
                  <Link to="/contact" className="pix-btn btn-outline">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-5">
              <small>
                * When using Free, the .adoc file character limit is 15.000 - without whitespace characters.
              </small>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Pricing;
